

import { Vue, Component, Prop } from 'vue-property-decorator';
import { Filter, Metadata } from '@/types/ListTypes';
import { isArray, isEqual, findIndex } from 'lodash';

@Component({
  name: 'MultiValueFilterDisplayValues',
})
export default class MultiValueFilterDisplayValues extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;

  get options(): Array<{ const: unknown, title: string|number, metadata?: Metadata}> {
    return this.filter.metadata?.options ?? [];
  }

  protected getAppliedValuesArray(): Array<unknown> {
    const appliedData = this.filter?.data as Array<unknown>;
    const appliedValues: Array<unknown> = [];

    this.options.forEach((value) => {
      appliedData.forEach((val) => {
        const selectionValue = val as Array<unknown>;
        if (isEqual(selectionValue, value.const)) {
          appliedValues.push(value.const);
        }
      });
    });

    if (!isArray(appliedValues)) {
      throw Error('applied filter values can\'t be found');
    }

    return appliedValues;
  }

  protected getDisplayValue(value: unknown): string {
    const correspondingOption = this.options.find((option) => option.const === value);
    const displayValue = correspondingOption?.title;

    if (displayValue === undefined) {
      throw Error(`No display ('title') value found for selection. Value selected: ${value}`);
    }

    if (correspondingOption?.metadata && correspondingOption?.metadata.group && correspondingOption?.metadata.label) {
      return `${correspondingOption?.metadata.label}: ${(displayValue).toString()}`;
    }

    return (displayValue).toString();
  }

  protected removeFilter(value: never): void {
    const appliedData = this.filter?.data as Array<unknown>;
    let indexOfValue = appliedData.indexOf(value as unknown);

    if (typeof value === 'object' && value !== null) {
      indexOfValue = findIndex(appliedData, value);
    }

    appliedData.splice(indexOfValue, 1);
    this.filter.applied = appliedData.length > 0;

    this.$emit('filter-removed');
  }
}
